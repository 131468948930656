@tailwind base;
@tailwind components;
@tailwind utilities;

/* navbar */
#nav__container {
    @apply 
    sm:px-6 md:px-6 lg:px-8
    w-full 
    lg:h-20
    top-0
    z-40
    bg-blue-gray-50
    shadow-md 
    fixed
    flex 
    items-center
}

#nav_lg {
    @apply
    flex  items-center justify-center
}

#link_to_home {
    @apply
    flex flex-shrink-0 items-center
}

#nav_list {
    @apply
    sm:ml-6 lg:gap-x-12 p-10 md:block lg:block hidden
}

#nav_list_ul {
    @apply
    flex
}

#nav_links {
    @apply
    flex items-center gap-x-2 text-gray-600 hover:bg-blue-700 hover:text-white rounded-md px-3 py-2  font-normal 
}

#nav_home_link {
    @apply
    flex items-center gap-x-2 text-gray-600 hover:bg-blue-700 hover:text-white rounded-md px-3 py-2 font-normal focus:font-bold
}

#nav_links_dropdown {
    @apply
    dropdown-content z-[1] text-gray-500  menu p-1 pt-10 pb-10 drop-shadow-md bg-blue-gray-50 rounded-box w-64
}

#nav_links_dropdown_item {
    @apply
    hover:scale-105 transition duration-500 bg-blue-500 text-white hover:bg-blue-800 hover:text-white hover:shadow m-1
}








/* home */

#home_container {
    @apply
    bg-white w-full align-middle justify-center 
}

#home_header {
    @apply
     justify-center mx-72 py-36 text-left 
}

#home_header_h1 {
    @apply
    text-4xl font-bold mx-32 tracking-tight text-gray-900 sm:text-6xl text-center hover:scale-x-110 transition duration-1000
}

#home_header_p {
    @apply
    mt-8 text-base leading-8 text-gray-600
}
#home_buttons_container{
    @apply
    mt-10 flex items-center gap-x-6
}

#button_blue {
    @apply
    drop-shadow-lg hover:scale-110 transition duration-500 
    inline-flex items-center px-3 py-0 h-10 text-sm font-semibold text-center 
    text-white bg-blue-700 rounded-md hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
}

#button_slate {
    @apply
    drop-shadow bg-blue-gray-100 flex items-center hover:scale-110 transition duration-500 text-sm font-semibold leading-6 text-gray-900 px-3 py-0 rounded-md h-10
}

#home_cards_container {
    @apply
    pt-24 pb-24 px-24 bg-blue-800 w-full grid grid-cols-3 gap-x-24 gap-y-20
}

#home_card_item_container{
    @apply
    hover:scale-110 hover:shadow-md hover:rounded-md hover:bg-blue-900 transition duration-500 pt-4
}

#home_card_item {
    @apply
    text-center justify-center align-middle flex 
}

#home_card_h5 {
    @apply
    justify-center items-center align-middle text-3xl text-center font-bold tracking-tight text-blue-gray-100 pb-6 
}

#home_card_p {
    @apply
    text-base leading-7 text-white text-left w-full   rounded-md
    p-6 px-6 
}
#home_wwd{
    @apply
    py-16 px-72
}
#home_wwd_h1{
    @apply
    text-4xl text-center text-blue-gray-700 font-bold mb-10
}
#wwd_items{
    @apply
    grid grid-cols-4 gap-0
}
#wwd_item{
    @apply
    bg-red-700 rounded-md px-2 py-2 hover:scale-105 hover:bg-red-800 transition duration-700  mx-2 my-4 flex
}
#home_wwd_p{
    @apply
    text-center text-white text-2xl ml-20
}






/* infrastructure */

#infrastructure_container {
    @apply
    bg-white w-full align-middle justify-center
}

#infrastructure_header {
    @apply
    max-w-2xl justify-center mx-auto sm:py-48 lg:py-28
}

#infrastructure_header_h1 {
    @apply
    mt-10 mb-20 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl text-center hover:scale-x-110 transition duration-1000 
}

#infrastructure_header_p {
    @apply
    mt-6 text-base leading-8 text-gray-600 text-left
}

#infrastructure_cards_container {
    @apply
    pt-20 pb-28 px-48 bg-blue-800 w-full grid grid-cols-1 gap-x-24 
}

#infrastructure_card_item_container{
    @apply
    hover:scale-110 hover:shadow-md hover:rounded-md hover:bg-blue-900 transition duration-500 p-10
}

#infrastructure_card_item {
    @apply
    text-center justify-center align-middle flex
}

#infrastructure_card_h5 {
    @apply
    justify-center items-center align-middle text-3xl text-center font-bold tracking-tight text-blue-gray-100 pb-6
}

#infrastructure_card_p {
    @apply
    text-base leading-7 text-white text-left   rounded-md
    p-6 px-6 mx-1 bg-red-700 
}
#infrastructure_consulting{
    @apply
    py-20 px-60
}
#infrastructure_consulting_h1{
    @apply
    text-center text-4xl font-bold pb-10 text-blue-gray-700
}
#infrastructure_consulting_cards{
    @apply
    grid grid-cols-2
}
#infrastructure_consulting_card{
    @apply
    p-10 m-3 bg-blue-800 rounded-md shadow-md hover:scale-105 transition duration-500 hover:bg-blue-900
}
#infrastructure_consulting_card_p{
    @apply
    text-white text-center font-semibold
}
#ims_content{
    @apply
    py-20 px-60
}
#ims_h1{
    @apply
    text-center text-4xl text-blue-gray-700 font-bold pb-10
}
#ims_p{
    @apply
    text-center text-blue-gray-600 px-32
} 
#ims_cards{
    @apply
    grid grid-cols-3 py-10 
}
#ims_card{
    @apply
    p-10 m-3 bg-red-700 rounded-md shadow-md hover:scale-105 transition duration-500 hover:bg-red-800
}
#ims_cars_p{
    @apply
    text-center text-white font-semibold
}
#ims_cards2{
    @apply
    grid grid-cols-4 py-10 
}
#ims_card_svg{
    @apply
    w-12 h-12 mx-auto mb-3
}





/* applications */

#applications_container {
    @apply
    bg-white w-full align-middle justify-center
}

#applications_header {
    @apply
    max-w-2xl justify-center mx-auto sm:py-48 lg:py-28
}

#applications_header_h1 {
    @apply
    mt-10 mb-20 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl text-center hover:scale-x-110 transition duration-1000 
}

#applications_header_p {
    @apply
    mt-6 text-base leading-8 text-gray-600 text-left
}

#applications_cards_container {
    @apply
    pt-16 pb-14 px-28 bg-blue-800 w-full grid grid-cols-2 gap-x-24 gap-y-20
}

#applications_card_item_container {
    @apply
    hover:scale-110 hover:shadow-md hover:rounded-md hover:bg-blue-900 transition duration-500 pt-4
}

#applications_card_item {
    @apply
    text-center justify-center align-middle flex 
}

 #applications_card_item_h5 {
    @apply
    justify-center items-center align-middle text-3xl text-center font-bold tracking-tight text-blue-gray-100 pb-6 
} 


 #applications_card_item_p {
    @apply
    text-base leading-7 text-white text-left w-full   rounded-md
    p-6 px-6 
}

#advantages_container {
    @apply
    w-full bg-white pt-16 pb-16 justify-center align-middle items-center
}







/* Resource Augmentation */

#resourceaugmentation_container {
    @apply
    bg-white w-full align-middle justify-center
}

#resourceaugmentation_header {
    @apply
    max-w-2xl justify-center mx-auto sm:py-48 lg:py-28
}

#resourceaugmentation_header_h1 {
    @apply
    mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl text-center hover:scale-x-110 transition duration-1000 
}

#resourceaugmentation_header_p {
    @apply
    text-base leading-8 text-gray-600 text-left mr-12
}

#resourceaugmentation_header_p_img {
    @apply
    grid grid-cols-2 align-middle justify-center items-center px-32 pb-32 ml-28
}

#resourceaugmentation_smallcards_container {
    @apply
    w-full bg-white pt-0 flex gap-x-12 justify-center
}

#resourceaugmentation_smallcards_container.cards_container_2 {
    @apply
    pb-16
}

#resourceaugmentation_smallcard {
    @apply
    bg-white h-44 w-44 rounded-lg m-2 items-center justify-center
    hover:scale-125 transition duration-500 shadow-md
}

#resourceaugmentation_smallcard_h5 {
    @apply
    text-center font-bold text-blue-700 pt-3
}

#talentpool_container {
    @apply
    bg-white w-full pt-16 pb-16 px-16 justify-center items-center align-middle
}

#talentpool_card {
    @apply
    bg-blue-800 justify-center items-center align-middle rounded-md mx-2 py-4 mb-6 text-blue-gray-50
}
#portfolios_content{
    @apply
    bg-blue-800 pt-10 pb-20
}
#portfolios_h1{
    @apply
    text-center pb-3 pt-12 text-4xl font-bold text-blue-gray-100
}
#portfolios_cards_container {
    @apply
    pt-10 pb-14 px-40 bg-blue-800 w-full grid grid-cols-3 gap-x-0 gap-y-20
}

#portfolio_card_item_container {
    @apply
    hover:scale-105 hover:shadow-md hover:rounded-md hover:bg-red-800 transition duration-500 pt-4 
    bg-red-700 shadow-md rounded-md mx-1 
}

#portfolios_card_item {
    @apply
    text-left justify-center grid grid-cols-2 mx-24
}

#portfolios_card_h5 {
    @apply
    justify-center items-center align-middle text-3xl text-center font-bold tracking-tight text-blue-gray-100 pb-6 
}

#portfolios_card_p {
    @apply
    mb-4 font-normal text-white dark:text-gray-400 flex  text-left
}

/* contact */

#contact_container {
    @apply
    bg-white w-full align-middle justify-center
}

#contact_header {
    @apply
    max-w-2xl justify-center mx-auto sm:py-48 lg:py-28 text-center 
}

#contact_header_h1 {
    @apply
    mt-6 mb-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl  hover:scale-x-110 transition duration-1000 
}

#contact_header_p {
    @apply
    mt-6 text-base leading-3 text-gray-600 
}

#contact_map{
    @apply
    bg-black
}
#mapresponsive{
    @apply
    overflow-hidden
    pb-40
    relative
}
#mapresponsize iframe {
    @apply
    absolute
}
#mapframe{
    @apply
    rounded-lg shadow-md mx-auto
}


/* about */
#about_container {
    @apply
    bg-white w-full align-middle justify-center
}

#about_header {
    @apply
    justify-center mx-52 grid grid-cols-2 sm:py-48 lg:py-28
}
#header_col2{
    @apply
    mx-10 my-0 bg-white rounded-md shadow-md py-10 hover:scale-105 transition duration-700
}
#header_col2_h1{
    @apply
     text-4xl mb-10 font-bold tracking-tight text-blue-500 sm:text-6xl text-center 
}
#header_col2_p{
    @apply
    text-xl text-blue-gray-600 leading-10 text-left ml-10 
}

#about_header_h1 {
    @apply
    mt-10 mb-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl text-center hover:scale-x-110 transition duration-1000 
}

#about_header_h1.h1_2 {
    @apply
    mt-0 mb-10 text-xl font-bold tracking-tight text-gray-600 sm:text-6xl text-center hover:scale-x-110 transition duration-1000 
}

#about_header_p {
    @apply
    mt-6 pb-0 text-base leading-8 text-gray-600 text-left
}
#about_row2{
    @apply
    justify-center mx-52 grid grid-cols-2
}
#about_details{
    @apply
    max-w-2xl justify-center mx-auto
}
#about_details_h1 {
    @apply
    mt-0 mb-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl text-center hover:scale-x-110 transition duration-1000 
}

#about_details_h1.h1_2 {
    @apply
    mt-0 mb-10 text-xl font-bold tracking-tight text-gray-600 sm:text-6xl text-center hover:scale-x-110 transition duration-1000 
}

#about_details_p {
    @apply
    mt-0 pb-10 text-base leading-8 text-gray-600 text-left
}
#why_details{
    @apply
    py-20 px-96 bg-blue-800 mt-10 text-base leading-10 text-blue-gray-600 text-left
}
#why_details_h1 {
    @apply
    mt-0 mb-10 text-4xl font-bold tracking-tight text-blue-gray-100 sm:text-6xl text-center hover:scale-x-110 transition duration-1000 
}
#why_p{
    @apply
    text-blue-gray-50
}
#trustedpartners{
    @apply
    bg-white py-32 px-52 
}
#trustedpartners_h1{
    @apply
    text-center text-blue-gray-600 text-4xl  pb-20
}
#trustedpartners_images{
    @apply
    grid grid-cols-9 gap-6
}
#trustedpartnerimg{
    @apply
    hover:scale-125 transition duration-500
}

/* IIM */
#IIM_container{
    @apply
    bg-white w-full align-middle justify-center
}
#IIM_header{
    @apply
    max-w-2xl justify-center mx-auto sm:py-48 lg:py-28
}

#IIM_header_h1{
    @apply
    mt-10 mb-20 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl text-center hover:scale-x-110 transition duration-1000 
}
#IIM_header_p{
    @apply
    mt-6 text-base leading-8 text-gray-600 text-left
}
#IIM_content{
    @apply
    pt-16 pb-14 px-28 bg-blue-800 w-full gap-x-24 gap-y-20
}
#IIM_intservicedesk{
    @apply
    text-center pt-4 pb-4
}
#intservicedesk_h1{
    @apply
    text-4xl text-blue-gray-100 font-bold
}
#intservicedesk_p{
    @apply
    text-base text-blue-gray-50 mx-72 pt-8 pb-8 leading-8
}

#servicedesk_cards{
    @apply
    bg-blue-800 pt-5 mb-10 mx-80 rounded-md leading-8
}
#servicedesk_card_p{
    @apply
    text-blue-gray-50 text-left bg-red-800 my-2 w-2/3 p-3 rounded-md shadow-md
}
#servicedesk_card_h{
    @apply
    text-2xl text-blue-gray-100 pb-4 font-bold text-left
}
#IIM_remoteinfmanage{
    @apply
    pt-16 pb-14 px-28 bg-white w-full gap-x-24 gap-y-20
}
#remoteinfmanage_h1{
    @apply
    text-4xl text-blue-gray-800 text-center
}
#remoteinfmanage_p{
    @apply
    text-blue-gray-500 py-10 mx-72 leading-8
}
#IIM_autoinfmanage{
    @apply
    pt-0 pb-20 px-28 bg-white w-full gap-x-24 gap-y-20
}
#autoinfmanage_h1{
    @apply
    text-4xl text-blue-gray-800 text-center
}
#autoinfmanage_p{
    @apply
    text-blue-gray-500 py-10 mx-72 leading-8
}
#AIMimg{
    @apply
    w-1/2 rounded-md mx-auto shadow-md
}


/* RPA */
#RPAsol_container{
    @apply
    bg-white w-full align-middle justify-center
}
#RPAsol_header{
    @apply
    max-w-2xl justify-center mx-auto sm:py-48 lg:py-28
}

#RPAsol_header_h1{
    @apply
    mt-10 mb-20 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl text-center hover:scale-x-110 transition duration-1000 
}
#RPAsol_header_p{
    @apply
    mt-6 text-base leading-8 text-gray-600 text-left
}
#RPAsol_content{
    @apply
    bg-blue-800 py-20 px-28
}
#RPAsol_content_p{
    @apply
    text-white mx-72 leading-8
}
#RPAsol_content_p2{
    @apply
    text-white mx-72 pt-12 leading-8
}
#RPAsol_benefits{
    @apply
    bg-white pb-32 pt-16 px-28 text-center
}
#RPAsol_benefits_h1{
    @apply
    mt-5 mb-20 text-4xl font-bold tracking-tight text-blue-gray-800
}
#RPAsol_benefits_p_container{
    @apply
    grid grid-cols-3 px-10 mx-32 py-10 text-blue-gray-50 font-bold leading-10 text-left bg-red-800 rounded-md shadow-md
}

/* RPA cloud */
#RPAcloud_container{
    @apply
    bg-white w-full align-middle justify-center
}
#RPAcloud_header{
    @apply
    max-w-2xl justify-center mx-auto sm:py-48 lg:py-28
}

#RPAcloud_header_h1{
    @apply
    mt-10 mb-20 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl text-center hover:scale-x-110 transition duration-1000 
}
#RPAcloud_header_p{
    @apply
    mt-6 text-base leading-8 text-gray-600 text-left
}
#RPAcloud_content{
    @apply
    bg-blue-800 py-20 px-28
}
#RPA_content_h1{
    @apply
    text-3xl text-center text-blue-gray-100 mx-72
}
#RPA_content_p_container{
    @apply
    mx-72 mt-12 grid grid-cols-3
}
#RPAcloud_card_item{
    @apply
    bg-blue-800 rounded-md hover:scale-110 hover:bg-blue-900 transition duration-500 mx-4 my-4 p-6
}
#RPAcloud_card_h1{
    @apply
    text-white text-xl font-bold text-center pb-2
}
#RPAcloud_card_p{
    @apply
    text-blue-gray-50 leading-8 text-center
}
#RPAcloud_differentiators{
    @apply
    px-72 pt-16 pb-16 grid grid-cols-2 bg-white
}
#RPAcloud_differentiators_h1{
    @apply
    text-6xl text-center text-blue-gray-800 mx-72
}
#RPAcloud_differentiators_p{
    @apply
    text-xl leading-10 text-blue-gray-700 ml-20 mb-6
}

/* RPA Industries */
#RPAindustries_container{
    @apply
    bg-white w-full align-middle justify-center
}
#RPAbanking_content{
    @apply
    bg-white py-20 px-72
}
#RPAbanking_h1{
    @apply
    mt-10 mb-16 text-4xl font-bold tracking-tight text-gray-800 sm:text-6xl text-center hover:scale-x-110 transition duration-1000
}
#RPAbanking_p{
    @apply
    mt-6 text-base leading-8 text-gray-600 text-left
}
#RPAbanking_challenges{
    @apply
    py-20
}
#RPAbanking_challenges_h1{
    @apply
    text-4xl text-center text-blue-gray-800
}
#RPAbanking_challenges_cards{
    @apply
    grid grid-cols-4
}
#RPAbanking_challenges_card_item{
    @apply
    mt-10 m-3 bg-blue-700 p-6 rounded-md hover:scale-105 hover:bg-blue-900 transition duration-500
}
#RPAbanking_challenges_card_h1{
    @apply
    text-2xl text-blue-gray-100 text-center pb-3 font-bold
}
#RPAbanking_challenges_card_p{
    @apply
    text-base text-blue-gray-50 leading-8
}
#RPAbanking_impact{
    @apply
    px-6
}
#RPAbanking_impact_h1{
    @apply
    text-2xl text-black pb-3
}
#RPAbanking_impact_p{
    @apply
    text-xl text-blue-gray-600 leading-8
}
#RPAinsurance{
    @apply
    bg-blue-800 px-72 py-20
}
#RPAinsurance_h1{
    @apply
    mt-10 mb-16 text-4xl font-bold tracking-tight text-white sm:text-6xl text-center hover:scale-x-110 transition duration-1000
}
#RPAinsurance_p{
    @apply
    text-base text-white text-left leading-10 pb-6
}
#RPAinsurance_processes_cards{
    @apply
    grid grid-cols-3 mt-10
}
#RPAinsurance_card_item{
    @apply
    bg-red-700 p-8 rounded-md hover:scale-110 hover:bg-red-800 transition duration-700 mx-8
}
#RPAinsurance_card_h1{
    @apply
    text-xl text-white text-center pb-4 font-bold
}
#RPAinsurance_card_p{
    @apply
    text-white leading-7 
}
#RPAtelecom{
    @apply
    bg-white px-80 py-10
}
#RPAtelecom_h1{
    @apply
    mt-4 mb-10 text-4xl font-bold tracking-tight text-blue-gray-800 sm:text-6xl text-center hover:scale-x-110 transition duration-1000
}
#RPAtelecom_p{
    @apply
    text-base text-blue-gray-700 text-left leading-10 pb-6
}

/* digital marketing */
#dm_container{
    @apply
    bg-white w-full align-middle justify-center
}
#dm_header {
    @apply
    mx-72 justify-center sm:py-48 lg:py-28
}
#dm_header_h1{
    @apply
    mt-10 mb-20 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl text-center hover:scale-x-110 transition duration-1000 
}
#dm_header_h2{
    @apply
    mt-10 text-2xl font-bold tracking-tight text-blue-gray-800 sm:text-6xl text-center
}
#dm_content1{
    @apply
    bg-blue-800 px-80 py-20
}
#dm_content1_h1{
    @apply
    text-center text-3xl text-white pb-8
}
#dm_content1_p{
    @apply
    text-left text-base text-white leading-8 px-20
}
#dm_content2{
    @apply
    bg-white px-80 py-20
}
#dm_content2_h1{
    @apply
    text-center text-3xl text-black pb-8
}
#dm_content2_p{
    @apply
    text-left text-base text-blue-gray-700 leading-8 px-20
}
#dm_p_list{
    @apply
    py-5 px-5 mx-20 mt-5 bg-blue-gray-50 w-1/2 rounded-md shadow-md leading-8 text-blue-gray-700
}
#dm_content3{
    @apply
    bg-blue-800 px-80 py-20
}
#dm_content3_h1{
    @apply
    text-center text-4xl text-white pb-8
}
#dm_content3_p{
    @apply
    text-left text-base text-white leading-8 px-20 pb-10
}
#content3_cards{
    @apply
    grid grid-cols-2
}
#content3_card_item{
    @apply
    p-6 mb-10 hover:scale-105 hover:bg-blue-900 hover:rounded-md hover:shadow-md transition duration-700
}
#content3_card_h1{
    @apply
    text-center text-2xl pb-3 text-white font-bold
}
#content3_card_p{
    @apply
    text-blue-gray-50 leading-8
}
#dm_content4{
    @apply
    px-52 py-32 bg-white 
}
#dm_content4_h1{
    @apply
    text-4xl text-center text-black pb-10
}
#dm_content4_cards{
    @apply
    grid grid-cols-4
}
#dm_content4_card_item{
    @apply
    bg-blue-700 m-2 p-5 rounded-md hover:scale-105 hover:bg-blue-800 transition duration-500
    text-white text-center font-bold
}


/* wed dev */
#webdev_container {
    @apply
    bg-white w-full align-middle justify-center
}

#webdev_header {
    @apply
    max-w-2xl justify-center mx-auto sm:py-48 lg:py-28
}

#webdev_header_h1 {
    @apply
    mt-10 mb-20 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl text-center hover:scale-x-110 transition duration-1000 
}

#webdev_header_p {
    @apply
    mt-6 text-base leading-8 text-gray-600 text-left
}
#webdev_services{
    @apply
    bg-blue-800 px-60 py-20
}
#webdev_h1{
    @apply
    text-4xl text-white text-center pb-10
}
#webdev_services_cards{
    @apply
    grid grid-cols-2
}
#webdev_card_item{
    @apply
    bg-blue-700 p-8 m-8 rounded-md shadow-md hover:scale-105 hover:bg-blue-900 transition duration-500
}
#webdev_card_h1{
    @apply
    text-2xl text-white text-center pb-3
}
#webdev_card_p{
    @apply
    text-blue-gray-50 text-center
}


/* pdf */
#pdf_container{
    @apply
    w-full h-screen bg-white flex items-center justify-center
}
#pdf_item{
    @apply
    h-full w-1/2 pt-20 pb-10
}


#footer_p{
    @apply
    text-blue-gray-900 text-sm pl-4 leading-7
}
#footer_links_title{
    @apply
    mb-3 text-gray-800 font-semibold
}
#footer_links_links{
    @apply
    py-1 text-sm text-gray-600 transition-colors hover:text-blue-gray-600 hover:underline
}








/* Media Queries for Mobile Screens */

@media only screen and (max-width: 1024px) {
    /* Common styles for mobile screens */
  
    /* home */

    #home_header {
      @apply
        mb-0 px-0 mx-0 py-16 pt-24
    }
  
    #home_header_h1 {
      @apply
        text-2xl
        mb-2
        mx-10
    }
  
    #home_header_p {
      @apply
        mt-2
        text-sm
        px-6
    }
  
    #home_cards_container {
        @apply
        pt-6 pb-10 px-2 bg-blue-800 w-full grid grid-cols-1 gap-x-2 gap-y-3
    }
    
    #home_card_item_container{
        @apply
        hover:scale-105 hover:shadow-md hover:rounded-md hover:bg-blue-900 transition duration-500 pt-4
    }
    
    #home_card_item {
        @apply
        text-center justify-center align-middle flex 
    }
    
    #home_card_h5 {
        @apply
        justify-center items-center align-middle text-base text-center font-bold tracking-tight text-blue-gray-100 pb-1
    }
    
    #home_card_p {
        @apply
        text-xs leading-5 text-white text-left w-full   rounded-md
        p-1 px-4 
    }
    #home_buttons_container{
        @apply
        mt-10 flex items-center gap-x-0
    }
    #button_blue {
        @apply
        ml-6 mr-3 py-3 h-8 text-xs font-normal
    }
    #button_slate {
        @apply
        px-3 py-3 h-8 text-xs font-normal
    }

    #ipad_p_button_container{
        @apply
        grid grid-cols-1 ml-0 mt-0 gap-y-0
    }

    #ipadimg {
        @apply
        w-56 h-56 mt-16 hover:scale-105 transition duration-500 mx-auto
    }
    #home_wwd{
        @apply
        py-10 px-8
    }
    #home_wwd_h1{
        @apply
        text-2xl text-center text-black font-bold mb-6
    }
    #wwd_items{
        @apply
        grid grid-cols-1
    }
    #wwd_item{
        @apply
        bg-red-700 rounded-md px-2 py-2 hover:scale-105 hover:bg-red-800 transition duration-700 w-3/4 mx-auto my-2 flex
    }
    #home_wwd_p{
        @apply
        text-center text-white text-base mx-auto
    }
    #wwdsvg{
        @apply
        w-12 h-12 hidden
    }
  

    /* Infrastructure Specific */
  
    #infrastructure_container {
        @apply
        bg-white w-full align-middle justify-center
    }
    
    #infrastructure_header {
        @apply
        mb-6 px-16
    }
    
    #infrastructure_header_h1 {
        @apply
        text-2xl sm:text-4xl pt-24 mt-0 mb-6 
    }
    
    #infrastructure_header_p {
        @apply
        mt-4
        text-sm
    }
    
    #infrastructure_cards_container {
        @apply
        pt-6 pb-10 px-2 bg-blue-800 w-full grid grid-cols-1 gap-x-2 gap-y-2
    }

    #infrastructure_card_item_container{
        @apply
        hover:scale-105 hover:shadow-md hover:rounded-md hover:bg-blue-900 transition duration-500 pt-4 px-10 
    }

    #infrastructure_card_item {
        @apply
        text-center justify-center align-middle grid grid-cols-2 mx-1
    }
   
    
    #infrastructure_card_h5 {
        @apply
        justify-center items-center align-middle text-base text-center font-bold tracking-tight text-blue-gray-100 pb-1
    }
    
    #infrastructure_card_p {
        @apply
        text-xs leading-5 text-white text-left  rounded-md
        p-1 px-2 m-1 mx-1
    }
    #infrastructure_consulting{
        @apply
        py-10 px-8
    }
    #infrastructure_consulting_h1{
        @apply
        text-center text-2xl font-bold pb-6 text-blue-gray-700
    }
    #infrastructure_consulting_cards{
        @apply
        grid grid-cols-1
    }
    #infrastructure_consulting_card{
        @apply
        p-5 m-2 bg-blue-800 rounded-md shadow-md hover:scale-105 transition duration-500 hover:bg-blue-900
    }
    #infrastructure_consulting_card_p{
        @apply
        text-white text-center font-semibold text-sm
    }
    #ims_content{
        @apply
        py-10 px-8
    }
    #ims_h1{
        @apply
        text-center text-2xl text-blue-gray-700 font-bold pb-8
    }
    #ims_p{
        @apply
        text-center text-blue-gray-600 px-0 text-sm
    } 
    #ims_cards{
        @apply
        grid grid-cols-1 py-6
    }
    #ims_card{
        @apply
        p-6 m-3 bg-red-700 rounded-md shadow-md hover:scale-105 transition duration-500 hover:bg-red-800
    }
    #ims_cars_p{
        @apply
        text-center text-white font-semibold text-sm
    }
    #ims_cards2{
        @apply
        grid grid-cols-2 py-10 
    }
    #ims_card_svg{
        @apply
        w-6 h-6 mx-auto mb-3
    }
    






    /* applications specific */
    
    #applications_container {
        @apply
        bg-white w-full align-middle justify-center
    }
    
    #applications_header {
        @apply
        mb-6 px-16
    }
    
    #applications_header_h1 {
        @apply
        text-2xl sm:text-4xl pt-24 mt-0 mb-6
    }
    
    #applications_header_p {
        @apply
        mt-4
        text-sm
    }
    
    #applications_cards_container {
        @apply
        pt-6 pb-10 px-2 bg-blue-800 w-full grid grid-cols-1 gap-x-2 gap-y-2
    }
    
    #applications_card_item_container {
        @apply
        hover:scale-105 hover:shadow-md hover:rounded-md hover:bg-blue-900 transition duration-500 pt-4 px-1
    }
    
    #applications_card_item {
        @apply
        text-center justify-center align-middle flex 
    }
    
     #applications_card_item_h5 {
        @apply
        justify-center items-center align-middle text-base text-center font-bold tracking-tight text-blue-gray-100 pb-1
    } 
    
    
     #applications_card_item_p {
        @apply
        text-xs leading-5 text-white text-left w-full   rounded-md
        p-1 px-4
    }
    
    #advantages_container {
        @apply
        w-full bg-white pt-16 pb-16 justify-center align-middle items-center
    }
    #softapp_tags_container{
        @apply
        grid grid-cols-2 px-2
    }
    #softapp_tags_p {
        @apply
        text-xs text-center p-1
    }
    #SAP_img{
        @apply
        h-8 w-16 mr-0 ml-4
    }
    #oracle_img{
        @apply
        h-8 w-16 mr-0 ml-4
    }
    #php_img{
        @apply
        h-8 w-16 mr-1
    }
    #c_img{
        @apply
        h-8 w-8 mr-1
    }
    #python_img{
        @apply
        h-8 w-8 mr-1
    }
    #java_img{
        @apply
        h-8 w-8 mr-1
    }

     /* Resource Augmentation specific */

#resourceaugmentation_container {
    @apply
    bg-white w-full align-middle justify-center
}

#resourceaugmentation_header {
    @apply
    mb-6 px-16
}

#resourceaugmentation_header_h1 {
    @apply
    text-2xl sm:text-4xl pt-24 mt-0 mb-6
}

#resourceaugmentation_header_p {
    @apply
    mt-4
    text-sm
}

#resourceaugmentation_header_p_img {
    @apply
    grid grid-cols-1 align-middle justify-center items-center px-10 pb-8 ml-0 
}

#resourceimg{
    @apply
    h-60 w-auto hover:scale-105 transition duration-500 hover:shadow-lg shadow-lg
}

#resourceaugmentation_smallcards_container {
    @apply
    w-full bg-white pt-8 px-10 grid grid-cols-2 gap-x-6 justify-center
}

#resourceaugmentation_smallcards_container.cards_container_2 {
    @apply
    pb-8
}

#resourceaugmentation_smallcard {
    @apply
    bg-white h-32 w-32 rounded-lg m-2 items-center justify-center
    hover:scale-105 transition duration-500 
}

#cards_imgs{
    @apply
    h-12 justify-center align-middle items-center ml-10 mt-5 w-auto
}

#resourceaugmentation_smallcard_h5 {
    @apply
    text-center font-bold text-blue-700 pt-3 text-xs
}

#talentpool_container {
    @apply
    bg-white w-full pt-4 pb-8 px-0 justify-center items-center align-middle
}

#talentpoolh1{
    @apply
    text-center text-black font-bold text-lg pb-6
}

#talentpoolp{
    @apply
    text-blue-gray-600 px-10 leading-6 text-sm justify-center
}

#talent_pool_cards_container{
    @apply
    grid grid-cols-1 px-5 py-8 text-center
}

#talentpool_card {
    @apply
    bg-blue-800 justify-center items-center align-middle rounded-md mx-2 py-4 mb-2 text-blue-gray-50 text-xs
}

#portfolios_cards_container {
    @apply
    pt-6 pb-10 px-2 bg-blue-800 w-full grid grid-cols-2 gap-x-2 gap-y-2
}

#portfolio_card_item_container {
    @apply
    hover:scale-105 hover:shadow-md bg-red-700 hover:rounded-md hover:bg-red-800 transition duration-500 pt-4
}

#portfolios_card_item {
    @apply
    text-center justify-center align-middle grid grid-rows-2 mx-0
}

#portfolios_card_h5 {
    @apply
    justify-center items-center align-middle text-base text-center font-bold tracking-tight text-blue-gray-100 pb-1
}

#portfolios_card_p {
    @apply
    text-xs leading-3 text-white text-left w-full   rounded-md
    p-1 px-4
}

/* contact specific */
/* contact */

#contact_container {
    @apply
    bg-white w-full align-middle justify-center
}

#contact_header {
    @apply
    max-w-2xl justify-center mx-auto sm:py-48 lg:py-28 text-center 
}

#contact_header_h1 {
    @apply
    text-2xl sm:text-4xl pt-24 mt-0 mb-6
}

#contact_header_p {
    @apply
    mt-4
    text-sm
}

#mapresponsive{
    @apply
    overflow-hidden
    pb-10
    pt-10
    relative
}
#mapresponsize iframe {
    @apply
    absolute
}
#mapframe{
    @apply
    rounded-lg shadow-md mx-auto w-72 h-52
}

/* about */
/* about */
#about_container {
    @apply
    bg-white w-full align-middle justify-center
}

#about_header {
    @apply
    justify-center mx-8 grid grid-cols-1 sm:py-48 lg:py-28
}
#header_col2{
    @apply
    mx-0 my-0  bg-white rounded-none shadow-md py-5 hover:scale-100 
}
#header_col2_h1{
    @apply
     text-xl mb-5 font-bold tracking-tight text-blue-500 sm:text-6xl text-center 
}
#header_col2_p{
    @apply
    text-sm text-blue-gray-600 leading-10 text-left ml-10 
}

#about_header_h1 {
    @apply
    mt-0 pt-20 mb-5 text-2xl font-bold tracking-tight text-gray-900  text-center hover:scale-x-110 transition duration-1000 
}

#about_header_h1.h1_2 {
    @apply
    mt-0 pt-0 mb-5 text-xl font-bold tracking-tight text-gray-600 text-center hover:scale-x-110 transition duration-1000 
}

#about_header_p {
    @apply
    mt-3 pb-0 text-sm leading-6 text-gray-600 text-left
}
#about_row2{
    @apply
    justify-center mx-8 grid grid-cols-1
}
#about_details{
    @apply
    max-w-2xl justify-center mx-auto
}
#about_details_h1 {
    @apply
    mt-10 mb-5 text-2xl font-bold tracking-tight text-gray-900 sm:text-6xl text-center hover:scale-x-110 transition duration-1000 
}

#about_details_h1.h1_2 {
    @apply
    mt-10 mb-5 text-xl font-bold tracking-tight text-gray-600 sm:text-6xl text-center hover:scale-x-110 transition duration-1000 
}

#about_details_p {
    @apply
    mt-0 pb-2 text-sm leading-6 text-gray-600 text-left
}
#why_details{
    @apply
    py-10 px-8 bg-blue-800 mt-5 text-sm leading-10 text-blue-gray-600 text-left
}
#why_details_h1 {
    @apply
    mt-0 mb-5 text-2xl font-bold tracking-tight text-blue-gray-100 sm:text-6xl text-center hover:scale-x-110 transition duration-1000 
}
#why_p{
    @apply
    text-blue-gray-50
}
#trustedpartners{
    @apply
    bg-white py-8 px-8
}
#trustedpartners_h1{
    @apply
    text-center text-blue-gray-600 text-2xl  pb-8
}
#trustedpartners_images{
    @apply
    grid grid-cols-4 gap-6
}
#trustedpartnerimg{
    @apply
    hover:scale-125 transition duration-500
}



/* IIM specific */
#IIM_container{
    @apply
    bg-white w-full align-middle justify-center
}
#IIM_header{
    @apply
    max-w-2xl justify-center mx-auto sm:py-48 lg:py-28 px-10
}

#IIM_header_h1{
    @apply
    text-2xl sm:text-4xl pt-24 mt-0 mb-0 
}
#IIM_header_p{
    @apply
    mt-4
    text-sm
}
#IIM_content{
    @apply
    pt-4 pb-14 px-0 bg-blue-800 w-full mt-8
}
#IIM_intservicedesk{
    @apply
    text-center pt-4 pb-0
}
#intservicedesk_h1{
    @apply
    text-xl text-white
}
#intservicedesk_p{
    @apply
    text-sm text-white mx-8 pt-6 pb-6 leading-5 text-left
}

#servicedesk_cards{
    @apply
    bg-blue-800 py-5 px-5 mb-6 mx-8 rounded-md leading-8 text-left
}
#servicedesk_card_p{
    @apply
    text-blue-gray-50 text-sm pb-2
}
#servicedesk_card_h{
    @apply
    text-xl text-blue-gray-100 pb-4
}
#IIM_remoteinfmanage{
    @apply
    pt-8 pb-0 px-0 bg-white w-full
}
#remoteinfmanage_h1{
    @apply
    text-xl text-blue-gray-800 text-center
}
#remoteinfmanage_p{
    @apply
    text-blue-gray-500 pt-4 mx-8 leading-6 text-sm
}
#IIM_autoinfmanage{
    @apply
    pt-8 pb-8 px-0 bg-white w-full
}
#autoinfmanage_h1{
    @apply
    text-xl text-blue-gray-800 text-center
}
#autoinfmanage_p{
    @apply
    text-blue-gray-500 pt-4 mx-8 leading-6 text-sm
}
#AIMimg{
    @apply
    w-2/3 rounded-md mx-auto shadow-md
}

/* RPA specific */
#RPAsol_container{
    @apply
    bg-white w-full align-middle justify-center
}
#RPAsol_header{
    @apply
    max-w-2xl justify-center mx-auto sm:py-48 lg:py-28 px-10
}

#RPAsol_header_h1{
    @apply
    text-2xl sm:text-4xl pt-24 mt-0 mb-0 
}
#RPAsol_header_p{
    @apply
    mt-4
    text-sm
}
#RPAsol_content{
    @apply
    pt-6 pb-8 px-0 bg-blue-800 w-full mt-8
}
#RPAsol_content_p{
    @apply
    text-white mx-10 leading-8 text-sm
}
#RPAsol_content_p2{
    @apply
    text-white mx-10 pt-6 leading-8 text-sm
}
#RPAsol_benefits{
    @apply
    bg-white pb-10 pt-8 px-0 text-center mx-8
}
#RPAsol_benefits_h1{
    @apply
    mt-5 mb-10 text-2xl font-bold tracking-tight text-blue-gray-800
}
#RPAsol_benefits_p_container{
    @apply
    grid grid-cols-1 px-6 mx-0 py-5 text-blue-gray-50 font-bold leading-10 text-left text-sm bg-red-800 rounded-md shadow-md
}


/* RPA cloud specific*/
#RPAcloud_container{
    @apply
    bg-white w-full align-middle justify-center
}
#RPAcloud_header{
    @apply
    max-w-2xl justify-center mx-auto sm:py-48 lg:py-28 px-10
}

#RPAcloud_header_h1{
    @apply
    text-2xl sm:text-4xl pt-24 mt-0 mb-0  
}
#RPAcloud_header_p{
    @apply
    mt-4
    text-sm
    mb-8
}
#RPAcloud_content{
    @apply
    bg-blue-800 py-10 px-8
}
#RPA_content_h1{
    @apply
    text-xl text-center text-white mx-8
}
#RPA_content_p_container{
    @apply
    mx-0 mt-2 grid grid-cols-1
}
#RPAcloud_card_item{
    @apply
    bg-blue-800 rounded-md hover:scale-105 transition duration-500 mx-0 my-0 p-6
}
#RPAcloud_card_h1{
    @apply
    text-blue-gray-100 text-lg font-bold text-center pb-2
}
#RPAcloud_card_p{
    @apply
    text-blue-gray-50 leading-8 text-center text-sm
}
#RPAcloud_differentiators{
    @apply
    px-2 pt-10 pb-8 grid grid-cols-2 bg-white
}
#RPAcloud_differentiators_h1{
    @apply
    text-2xl text-left text-blue-gray-800 ml-5
}
#RPAcloud_differentiators_p{
    @apply
    text-sm leading-3 text-blue-gray-700 ml-0 mb-6
}

/* RPA Industries specific */
#RPAindustries_container{
    @apply
    bg-white w-full align-middle justify-center
}
#RPAbanking_content{
    @apply
    bg-white py-8 px-8
}
#RPAbanking_h1{
    @apply
    mt-16 mb-6 text-2xl font-bold tracking-tight text-gray-900 text-center hover:scale-x-110 transition duration-1000
}
#RPAbanking_p{
    @apply
    mt-6 text-sm leading-6 text-gray-600 text-left
}
#RPAbanking_challenges{
    @apply
    py-10
}
#RPAbanking_challenges_h1{
    @apply
    text-lg font-bold text-center text-blue-gray-800
}
#RPAbanking_challenges_cards{
    @apply
    grid grid-cols-1
}
#RPAbanking_challenges_card_item{
    @apply
    mt-5 bg-blue-700 p-3 rounded-md hover:scale-105 hover:bg-blue-900 transition duration-500
}
#RPAbanking_challenges_card_h1{
    @apply
    text-lg text-blue-gray-100 text-center pb-2
}
#RPAbanking_challenges_card_p{
    @apply
    text-sm text-blue-gray-50 leading-7
}
#RPAbanking_impact{
    @apply
    px-6
}
#RPAbanking_impact_h1{
    @apply
    text-xl text-blue-gray-800 pb-3
}
#RPAbanking_impact_p{
    @apply
    text-sm text-blue-gray-600 leading-8
}
#RPAinsurance{
    @apply
    bg-blue-800 px-8 py-10
}
#RPAinsurance_h1{
    @apply
    mt-6 mb-6 text-xl font-bold tracking-tight text-white sm:text-6xl text-center hover:scale-x-105 transition duration-1000
}
#RPAinsurance_p{
    @apply
    text-sm text-white text-left leading-6 pb-6
}
#RPAinsurance_processes_cards{
    @apply
    grid grid-cols-1 mt-0
}
#RPAinsurance_card_item{
    @apply
    bg-red-700 p-5 my-2 rounded-md hover:scale-105 hover:bg-red-800 transition duration-700
}
#RPAinsurance_card_h1{
    @apply
    text-base text-white text-center pb-4
}
#RPAinsurance_card_p{
    @apply
    text-white leading-6 text-sm
}
#RPAtelecom{
    @apply
    bg-white px-10 py-2
}
#RPAtelecom_h1{
    @apply
    mt-4 mb-8 text-xl font-bold tracking-tight text-black sm:text-6xl text-center hover:scale-x-105 transition duration-1000
}
#RPAtelecom_p{
    @apply
    text-sm text-blue-gray-700 text-left leading-6 pb-4
}



/* digital marketing */
#dm_container{
    @apply
    bg-white w-full align-middle justify-center
}
#dm_header {
    @apply
    mx-8 justify-center sm:py-48 lg:py-28
}
#dm_header_h1{
    @apply
    mt-0 mb-10 pt-20 text-2xl font-bold tracking-tight text-gray-900 sm:text-6xl text-center hover:scale-x-110 transition duration-1000 
}
#dm_header_h2{
    @apply
    mt-0 mb-10 text-xl font-bold tracking-tight text-gray-900 sm:text-6xl text-center
}
#dm_content1{
    @apply
    bg-blue-800 px-8 py-10
}
#dm_content1_h1{
    @apply
    text-center text-2xl text-white pb-8
}
#dm_content1_p{
    @apply
    text-left text-sm text-white leading-5 px-8
}
#dm_content2{
    @apply
    bg-white px-8 py-10
}
#dm_content2_h1{
    @apply
    text-center text-2xl text-black pb-8
}
#dm_content2_p{
    @apply
    text-left text-sm text-blue-gray-700 leading-5 px-8
}
#dm_p_list{
    @apply
    py-5 px-5 mx-10 mt-5 bg-blue-gray-50 w-auto rounded-md shadow-md leading-8 text-blue-gray-700 text-sm
}
#dm_content3{
    @apply
    bg-blue-800 px-8 py-10
}
#dm_content3_h1{
    @apply
    text-center text-2xl text-white pb-8
}
#dm_content3_p{
    @apply
    text-left text-sm text-white leading-5 px-0 pb-10
}
#content3_cards{
    @apply
    grid grid-cols-2
}
#content3_card_item{
    @apply
    p-2 mb-6 hover:scale-105 hover:bg-blue-900 hover:rounded-md hover:shadow-md transition duration-700
}
#content3_card_h1{
    @apply
    text-center text-xl pb-3 text-white font-bold
}
#content3_card_p{
    @apply
    text-blue-gray-50 leading-5 text-sm
}
#dm_content4{
    @apply
    px-2 py-16 bg-white 
}
#dm_content4_h1{
    @apply
    text-2xl text-center text-black pb-10
}
#dm_content4_cards{
    @apply
    grid grid-cols-3
}
#dm_content4_card_item{
    @apply
    bg-blue-700 m-1 p-2 rounded-md hover:scale-105 hover:bg-blue-800 transition duration-500
    text-white text-center font-bold text-sm
}


/* wed dev specific */
#webdev_container {
    @apply
    bg-white w-full align-middle justify-center
}

#webdev_header {
    @apply
    max-w-2xl justify-center mx-auto sm:py-48 lg:py-28 px-8 pb-10
}

#webdev_header_h1 {
    @apply
    mt-0 pt-24 mb-10 text-2xl font-bold tracking-tight text-gray-900 sm:text-6xl text-center hover:scale-x-110 transition duration-1000 
}

#webdev_header_p {
    @apply
    mt-6 text-sm leading-5 text-gray-600 text-left
}
#webdev_services{
    @apply
    bg-blue-800 px-8 py-10
}
#webdev_h1{
    @apply
    text-2xl text-white text-center pb-0
}
#webdev_services_cards{
    @apply
    grid grid-cols-1
}
#webdev_card_item{
    @apply
    bg-blue-700 p-8 m-0 my-3 rounded-md shadow-md hover:scale-105 hover:bg-blue-900 transition duration-500
}
#webdev_card_h1{
    @apply
    text-xl text-white text-center pb-3
}
#webdev_card_p{
    @apply
    text-blue-gray-50 text-center text-sm
}

/* pdf specific */
/* pdf */
#pdf_container{
    @apply
    w-full px-0 h-screen bg-white flex items-center justify-center
}
#pdf_item{
    @apply
    h-3/4 w-3/4 pt-0 pb-0
}




#footer_container{
    @apply
    relative w-full bg-blue-gray-50 pt-8
}
#footerlogo{
    @apply
    h-12 mx-auto
}
#footer_p{
    @apply
    text-blue-gray-900 text-xs leading-7 text-center
}
#footer_links_title{
    @apply
    mb-3 mt-3 text-gray-800 font-semibold
}
#footer_links_links{
    @apply
    py-1 text-xs text-gray-600 transition-colors hover:text-blue-gray-600 hover:underline
}

}



 